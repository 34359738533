type ComposeArgs = (arg: unknown) => unknown;

export const compose =
  <T extends unknown, R extends unknown>(...fns: ComposeArgs[]) =>
  (initial: T): R =>
    fns.reduceRight((acc, cur) => cur(acc), initial) as R;

export const isFunction = (value: unknown): boolean =>
  typeof value === 'function';

export const omit =
  <T extends { [name: string]: unknown }>(keys: string[]) =>
  (sourceObj: T): Partial<T> =>
    Object.keys(sourceObj)
      .filter((key) => !keys.includes(key))
      .reduce<Partial<T>>((acc, cur: keyof T) => {
        acc[cur] = sourceObj[cur];

        return acc;
      }, {});

export const pickBy = <T extends { [name: string]: U }, U>(
  sourceObj: T,
  predicate: (val: U) => boolean,
): Partial<T> =>
  Object.keys(sourceObj).reduce<Partial<T>>((acc, cur: keyof T) => {
    if (!predicate(sourceObj[cur])) {
      return acc;
    }
    acc[cur] = sourceObj[cur];

    return acc;
  }, {});
