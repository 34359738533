import Cookies from 'js-cookie';
import mitt from 'mitt';
import Script from 'next/script';
import { Fragment, useEffect, useState } from 'react';

import { setNinetailedConsent } from 'shared/services/ninetailed/setup';
import { dispatchPageViewEvent } from 'shared/services/tracker/events';
import isServer from 'shared/utils/is-server';

import logger from '../../../scripts/logger';

/*
 * This is the name of the cookie set by OneTrust.
 */
export const ONETRUST_CONSENT_COOKIE = 'OptanonConsent';

export const COOKIE_CONSENT_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

/**
 * All cookies are divided into categories based on their purpose.
 * Users can choose which categories they want to consent to.
 * [View all categories on the OneTrust dashboard](https://app-uk.onetrust.com/cookies/categorizations?tab=Categories)
 * (login required).
 */
export type CookieConsentCategory =
  | 'STRICTLY_NECESSARY'
  | 'PERFORMANCE'
  | 'FUNCTIONAL'
  | 'TARGETING'
  | 'SOCIAL_MEDIA';

/**
 * These ids are how OneTrust refers to the consent categories internally.
 */
export type OneTrustCookieConsentCategoryId =
  | 'C0001'
  | 'C0002'
  | 'C0003'
  | 'C0004'
  | 'C0005';

// Mapping OneTrust's internal consent category ids to their human readable names.
const CONSENT_CATEGORIES: Record<
  OneTrustCookieConsentCategoryId,
  CookieConsentCategory
> = {
  C0001: 'STRICTLY_NECESSARY',
  C0002: 'PERFORMANCE',
  C0003: 'FUNCTIONAL',
  C0004: 'TARGETING',
  C0005: 'SOCIAL_MEDIA',
};

// Ensure default is limited to strictly necessary cookies
const DEFAULT_CONSENT_CATEGORIES: CookieConsentCategory[] = [
  'STRICTLY_NECESSARY',
];

// The OneTrustSDK type is incomplete and should be extended if necessary.
type OneTrustSDK = {
  OnConsentChanged: (
    callback: (event: CustomEvent<OneTrustCookieConsentCategoryId[]>) => void,
  ) => void;
  ToggleInfoDisplay: () => void;
  changeLanguage: (locale: string) => void;
  getGeolocationData: () => { country: string; state: string };
};

declare global {
  interface Window {
    OneTrust?: OneTrustSDK;
    CookieConsentInit?: () => void;
  }
}

const COOKIE_CONSENT_INIT = 'CookieConsentInit';
let IS_INITIALIZED = false;

/**
 * This component loads and initializes the cookie consent script.
 * The script must be placed before any other script in the site in order to
 * ensure the consent banner is loaded before any other scripts have the chance
 * to load or set cookies.
 */
export function CookieConsentScript() {
  return (
    <Fragment>
      <Script
        strategy="beforeInteractive"
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        charSet="UTF-8"
        data-document-language="true"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_ID}
      />
      <Script
        id="optanon-wrapper"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          // The OneTrust SDK calls this function once it has loaded and on every consent change.
          // The `init` callback is added to the `window` at the bottom of this file.
          __html: `
          function OptanonWrapper() {
              var init = window['${COOKIE_CONSENT_INIT}'];
              if (typeof init === 'function') {
                init();
              }
            }`,
        }}
      />
    </Fragment>
  );
}

/*
 * Checks whether the country OneTrust assigns to the user requires consent
 * (as the geolocation data may not match URL params or merchant profile)
 * provides value of "true" by default
 */
function isConsentEnabled(): boolean {
  if (!window.OneTrust) {
    logger.warning('[OneTrust] The OneTrust SDK is not available.');
    return true;
  }

  const geolocationData = window.OneTrust.getGeolocationData();

  if (geolocationData && geolocationData.country) {
    return COOKIE_CONSENT_COUNTRIES.includes(geolocationData.country);
  }

  return true;
}

/**
 * Reads and parses the OneTrust cookie that contains the currently active
 * cookie consent category ids and returns them as human readable names.
 */
export function getActiveConsentCategories(): CookieConsentCategory[] {
  const consentCookie = Cookies.get(ONETRUST_CONSENT_COOKIE);

  // TODO: remove this line once OneTrust is loaded in all countries
  if (!window.OneTrust) {
    return Object.values(CONSENT_CATEGORIES);
  }

  if (!consentCookie) {
    return DEFAULT_CONSENT_CATEGORIES;
  }

  try {
    // The OneTrust SDK serializes the cookie value as URL params.
    const parsedConsentCookie = new URLSearchParams(consentCookie);

    // The consent categories are stored in the format 'C0001:1,C0002:0'
    // where '0' means consent NOT given and '1' means consent given.

    const consentGroups = parsedConsentCookie.get('groups');

    if (!consentGroups) {
      return DEFAULT_CONSENT_CATEGORIES;
    }
    const consentCategories = consentGroups
      .split(',')
      .reduce((categories, categoryString) => {
        const [categoryId, categoryState] = categoryString.split(':') as [
          OneTrustCookieConsentCategoryId,
          '0' | '1',
        ];
        const category = CONSENT_CATEGORIES[categoryId];
        const isCategoryActive = categoryState === '1';

        if (category && isCategoryActive) {
          categories.push(category);
        }

        return categories;
      }, [] as CookieConsentCategory[]);
    return consentCategories;
  } catch (err: unknown) {
    if (err instanceof Error) {
      logger.error(err, '[OneTrust] Failed to parse consent cookie.');
    }
    return DEFAULT_CONSENT_CATEGORIES;
  }
}

export type CookieConsentEvents = {
  init: {
    categories: CookieConsentCategory[];
  };
  change: {
    categories: CookieConsentCategory[];
  };
  enable: boolean;
};

export const shouldLoadCookieConsentScript = () =>
  // Always load Cookie Consent
  // regardless of user's locale and
  // geolocation.
  // OneTrust Script will automatically
  // display the GDPR cookie banner only when
  // the user geolocation is in the EU (in all locales).
  process.env.NEXT_PUBLIC_ONETRUST_ENABLED === 'true' &&
  (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ||
    process.env.NEXT_PUBLIC_VERCEL_PREVIEW_ENV_STAGING === 'true');

// This custom event emitter acts as a bridge between the OneTrust SDK and the
// exported consent API. This abstraction layer will make it easier to switch
// to a different provider in the future.
// Furthermore, it is necessary to prevent memory leaks when subscribing to
// events from React since the OneTrust SDK doesn't offer a way to unsubscribe.
export const cookieConsentEvents = mitt<CookieConsentEvents>();

export function useCookieConsent(): {
  categories: CookieConsentCategory[];
  isEnabled: boolean;
} {
  const [isEnabled, setEnabled] = useState(shouldLoadCookieConsentScript());
  const [categories, setCategories] = useState<CookieConsentCategory[]>(
    DEFAULT_CONSENT_CATEGORIES,
  );
  // The state needs to be updated *after* the initial render to prevent
  // hydration mismatches when server-side rendering.
  useEffect(() => {
    // Ensure we only revaluate if the consent is enabled on the first render
    // if OneTrust is already available on mount. If not, we leave the reevaluation
    // to the cookieConsentsEvents on 'enable' event which will be triggered after
    // the SDK is ready.
    if (window.OneTrust) {
      setEnabled(isConsentEnabled());
    }

    setCategories(getActiveConsentCategories());
  }, []);

  useEffect(() => {
    const handleEnable = (event: CookieConsentEvents['enable']) => {
      setEnabled(event);
    };

    cookieConsentEvents.on('enable', handleEnable);

    return () => {
      cookieConsentEvents.off('enable', handleEnable);
    };
  }, []);

  useEffect(() => {
    const handleChange = (event: CookieConsentEvents['change']) => {
      setCategories(event.categories);
    };

    cookieConsentEvents.on('change', handleChange);

    return () => {
      cookieConsentEvents.off('change', handleChange);
    };
  }, []);

  return { isEnabled, categories };
}

/**
 * Attempts to open the cookie preference modal which also contains the link
 * to the cookie policy.
 */
export function showCookieConsentPreferences(): void {
  if (!window.OneTrust) {
    logger.warning('[OneTrust] The OneTrust SDK is not available.');
    return;
  }
  window.OneTrust.ToggleInfoDisplay();
}

function init() {
  // The OneTrust SDK calls the init callback multiple times.
  // This check makes sure it's only executed once.
  if (IS_INITIALIZED) {
    return;
  }

  IS_INITIALIZED = true;

  if (!window.OneTrust) {
    logger.warning(
      '[OneTrust] The OneTrust SDK is not available on init, this should never happen.',
    );
    return;
  }

  // FIXME: OneTrust does not support the German Luxembourg (de-LU) locale.
  // Their support suggested to use the Dutch Luxembourg locale (nl-LU)
  // to store the translations until they fix it.
  // Track solution progress here: https://ideas.onetrust.com/ideas/OT-I-11995
  // Once the issue has been solved we need to remove this logic.
  const locale = window.navigator.language;

  if (locale?.toLowerCase() === 'de-lu') {
    window.OneTrust.changeLanguage('nl-LU');
  }

  const initialCategories = getActiveConsentCategories();
  const isEnabled = isConsentEnabled();

  setNinetailedConsent(initialCategories.includes('FUNCTIONAL'));

  cookieConsentEvents.emit('init', { categories: initialCategories });
  cookieConsentEvents.emit('enable', isEnabled);

  window.OneTrust.OnConsentChanged((event) => {
    const categoryIds = event.detail;
    const categories = categoryIds.map((id) => CONSENT_CATEGORIES[id]);

    cookieConsentEvents.emit('change', { categories });

    // No need to update the GTM consent here, OneTrust does it for us.
  });

  const handleChange = (event: CookieConsentEvents['change']) => {
    if (
      event.categories.includes('PERFORMANCE') ||
      event.categories.includes('FUNCTIONAL')
    ) {
      void dispatchPageViewEvent({ isConsentUpdate: true });
    }

    setNinetailedConsent(event.categories.includes('FUNCTIONAL'));
  };

  cookieConsentEvents.on('change', handleChange);
}

if (!isServer) {
  if (window.OneTrust) {
    // The OneTrust SDK has already loaded and is available.
    init();
  } else {
    // The OneTrust SDK hasn't loaded yet and will call this callback when ready
    // (see the CookieConsentScript component above).
    window[COOKIE_CONSENT_INIT] = init;
  }
}
