import type { NextRouter } from 'next/router';

import { FEATURE_FLAGS, type FeatureFlag } from 'shared/constants/FeatureFlags';

const FEATURE_FLAGS_SEPARATOR = ',';

const NO_FEATURE_FLAGS: FeatureFlag[] = [];

export const isValidFeatureFlag = (feature: string): feature is FeatureFlag =>
  FEATURE_FLAGS.includes(feature as FeatureFlag);

const parseFeatureFlags = (
  featuresQuery: NextRouter['query'][number],
): string[] => {
  const requestedFeatures = Array.isArray(featuresQuery)
    ? featuresQuery
    : featuresQuery.split(FEATURE_FLAGS_SEPARATOR);

  return requestedFeatures;
};

export const getValidFeatures = (
  featuresQuery?: NextRouter['query'][number],
): FeatureFlag[] => {
  if (!featuresQuery) {
    return NO_FEATURE_FLAGS;
  }

  const requestedFeatures = parseFeatureFlags(featuresQuery);
  const validFeatures = requestedFeatures.filter(isValidFeatureFlag);
  const uniqueFeatures = [...new Set(validFeatures)];

  return uniqueFeatures;
};
