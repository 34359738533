import type { PaymentMethod } from 'shared/infra/commerceLayer/types';

export enum PaymentActionTypes {
  SET_LOADING = '@payment/SET_LOADING',
  SET_ERROR = '@payment/SET_ERROR',

  ATTACH_PAYMENT_METHOD_REQUEST = '@payment/ATTACH_PAYMENT_METHOD_REQUEST',
  ATTACH_PAYMENT_METHOD_SUCCESS = '@payment/ATTACH_PAYMENT_METHOD_SUCCESS',
  ATTACH_PAYMENT_METHOD_FAILURE = '@payment/ATTACH_PAYMENT_METHOD_FAILURE',

  ATTACH_CHECKOUT_ID = '@payment/ATTACH_CHECKOUT_ID',
  ATTACH_PAYMENT_CODE = '@payment/ATTACH_PAYMENT_CODE',
  ATTACH_DOWNLOAD_URL = '@payment/ATTACH_DOWNLOAD_URL',

  RESET_PAYMENT = '@payment/RESET_PAYMENT',
}

export type PaymentState = {
  id: string;
  reference: PaymentMethod;
  checkoutId: string;
  paymentCode: string;
  documentDownloadUrl: string;
};

export interface SetPaymentLoadingAction {
  type: PaymentActionTypes.SET_LOADING;
  payload: { isLoading?: boolean };
}

export interface SetPaymentErrorAction {
  type: PaymentActionTypes.SET_ERROR;
  payload: { error: string };
}

export interface AttachPaymentMethod {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST;
}

export interface AttachPaymentMethodSuccess {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS;
  payload: Pick<PaymentState, 'id' | 'reference'>;
}

export interface AttachPaymentMethodFailure {
  type: PaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE;
  payload: { error: string };
}

export interface AttachCheckoutId {
  type: PaymentActionTypes.ATTACH_CHECKOUT_ID;
  payload: { checkoutId: string };
}

export interface AttachPaymentCode {
  type: PaymentActionTypes.ATTACH_PAYMENT_CODE;
  payload: { code: string };
}

export interface AttachDocumentDownloadUrl {
  type: PaymentActionTypes.ATTACH_DOWNLOAD_URL;
  payload: { documentDownloadUrl: string };
}

export interface ResetPayment {
  type: PaymentActionTypes.RESET_PAYMENT;
}

export type PaymentActions =
  | SetPaymentLoadingAction
  | SetPaymentErrorAction
  | AttachPaymentMethod
  | AttachPaymentMethodSuccess
  | AttachPaymentMethodFailure
  | AttachCheckoutId
  | AttachPaymentCode
  | AttachDocumentDownloadUrl
  | ResetPayment;
