import isServer from 'shared/utils/is-server';
import {
  LOCALES,
  DEFAULT_LOCALE,
  LOCALES_WITH_SMALL_TRAFFIC,
} from 'tools/locales';

export const containsISOLocaleLikeString = (string: string): boolean =>
  /^\/(\D{2})-(\D{2})(\/)?.*/.test(string);

export const isISOLocaleLikeString = (string: string): boolean =>
  /(\D{2})-(\D{2})/.test(string);

export const getCountryCodeFromISOLocale = (locale = DEFAULT_LOCALE): string =>
  isISOLocaleLikeString(locale)
    ? locale.split('-').pop().toUpperCase()
    : undefined;

export const getLanguageFromISOLocale = (locale = DEFAULT_LOCALE): string =>
  isISOLocaleLikeString(locale) ? locale.split('-')[0] : undefined;

export const encounteredUnsupportedLocale = (url: string): boolean => {
  try {
    const [, locale] = /^.*\/(\D{2}-\D{2}).*$/.exec(url);
    return !(LOCALES as string[]).find(
      (supportedLocale) =>
        supportedLocale.toLowerCase() === locale.toLowerCase(),
    );
  } catch (e) {
    // didn't encounter a locale in the url
    return false;
  }
};

export const findAppropriateLocaleForUrl = (
  url: string,
): string | undefined => {
  try {
    const [, locale] = /^.*\/(\D{2}-\D{2}).*$/.exec(url);
    const country = getCountryCodeFromISOLocale(locale);
    if (
      (LOCALES as string[])
        .map((localeToMatch) => localeToMatch.toLowerCase())
        .includes(locale.toLowerCase())
    ) {
      return locale;
    }

    return (LOCALES as string[]).find((supportedLocale) =>
      supportedLocale.toLowerCase().includes(country.toLowerCase()),
    ) as unknown as string | undefined;
  } catch (e) {
    // didn't encounter a locale in the url
    return undefined;
  }
};

export const findAppropriateCountryForUrl = (url: string): string | undefined =>
  getCountryCodeFromISOLocale(findAppropriateLocaleForUrl(url) ?? null);

export const replaceISOLocaleInUrl = (
  url: string,
  rewriteLocale: string,
): string => {
  let output = '';
  // enable rewriting without locale
  const isRewriteEmpty = rewriteLocale === '';
  if (!isRewriteEmpty && !isISOLocaleLikeString(rewriteLocale)) {
    return url;
  }
  // rewrite locale
  output = url.replace(/\D{2}-\D{2}/, rewriteLocale);
  // if there's no locale specified remove the preceeding /
  if (isRewriteEmpty) {
    output = output.replace('/', '');
  }
  return output;
};

export const removeSmallTrafficLocales = (locales: string[]): string[] =>
  locales.filter((locale) => !LOCALES_WITH_SMALL_TRAFFIC.includes(locale));

// find appropriate locale for a given country.
// ex, for FR -> fr-FR
export const findISOLocaleForCountry = (country) =>
  (LOCALES as string[]).find((locale) => locale.includes(`-${country}`));

export const readWindowPathnameWithDefaultLocale = (
  pathname = !isServer && window.location.pathname,
) =>
  containsISOLocaleLikeString(pathname)
    ? pathname
    : // next.js is handling the default locale which might not be present on the location object
      `/${DEFAULT_LOCALE}${pathname}`;
