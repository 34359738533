import type { Span as APISpan } from '@opentelemetry/api';
import type { Span as SDKSpan } from '@opentelemetry/sdk-trace-base';

export const setOrgSpanAttributes = (span: SDKSpan | APISpan) => {
  span.setAttributes({
    'sumup.org.squad': process.env.NEXT_PUBLIC_OTEL_ATTRIBUTE_ORG_SQUAD,
    'sumup.org.tribe': process.env.NEXT_PUBLIC_OTEL_ATTRIBUTE_ORG_TRIBE,
    'sumup.org.comm.slack':
      process.env.NEXT_PUBLIC_OTEL_ATTRIBUTE_ORG_SLACK_CHANNEL,
    'sumup.org.vcs_repo': process.env.NEXT_PUBLIC_OTEL_ATTRIBUTE_ORG_VCS_REPO,
  });
};
