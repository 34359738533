import { createWrapper } from 'next-redux-wrapper';
import { useSelector, type TypedUseSelectorHook } from 'react-redux';
import { createStore, type Store } from 'redux';

import type { AddressState } from 'shared/store/address/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderState } from 'shared/store/order/types';
import type { PaymentGlobalState } from 'shared/store/payment/reducers';
import type { PromotionsState } from 'shared/store/promotions/types';
import { rootReducer } from 'shared/store/rootReducer';

export interface AppState {
  order: OrderState;
  addresses: AddressState;
  catalog: CatalogState;
  promotions: PromotionsState;
  payment: PaymentGlobalState;
}

export const configStore = (): Store<AppState> => {
  const store = createStore(rootReducer);

  return store;
};

/**
 * The `useTypedSelector` was created to avoid repeating
 * the `AppState` type declaration throughout every place
 * the react-redux's `useSelector` is being used.
 */
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

const nextReduxWrapper = createWrapper(configStore, { debug: false });

export const withNextRedux = nextReduxWrapper.withRedux;
