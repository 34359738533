import { combineReducers } from 'redux';

import { addresses } from 'shared/store/address/reducers';
import { catalog } from 'shared/store/catalog/reducers';
import { order } from 'shared/store/order/reducers';
import payment from 'shared/store/payment/reducers';
import { promotions } from 'shared/store/promotions/reducers';

export const rootReducer = combineReducers({
  order,
  addresses,
  catalog,
  promotions,
  payment,
});
