import type { EventTags, UserAttributes } from '@optimizely/optimizely-sdk';

import { optimizely } from 'shared/services/optimizely';
import type {
  AddressSuggestionSelectedCustomData,
  AuthEventCustomData,
  GoToActivationCustomData,
  GoToCheckoutCustomData,
  GoToPaymentCustomData,
  ManageProductCustomData,
  MoreInfoCustomData,
  ProceedAsRegisteredUserCustomData,
  PurchaseEventCustomData,
  SkipShopEventCustomData,
} from 'shared/services/tracker/types';

interface OptimizelyTrack {
  eventKey: OptimizelyEventName;
  overrideUserId?: string | EventTags;
  overrideAttributes?: UserAttributes;
  eventTags?: EventTags;
}

type OptimizelyEventName = (typeof OPTIMIZELY_EVENT_NAMES)[number];

const OPTIMIZELY_EVENT_NAMES = [
  'signup',
  'login',
  'purchase',
  'go_to_activation',
  'go_to_checkout',
  'address_suggestion_selected',
  'skip_shop',
  'more_info_click',
  'proceed_as_registered_user',
  'go_to_payment',
  'addToCart',
  'goToCart',
  'visited_product_details_page',
] as const;

function optimizelyTrack({
  eventKey,
  overrideUserId,
  overrideAttributes,
  eventTags,
}: OptimizelyTrack): void {
  optimizely.track(eventKey, overrideUserId, overrideAttributes, eventTags);
}

export function signupHandler(customData: AuthEventCustomData): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  optimizelyTrack({
    eventKey: 'signup',
    overrideUserId: { merchantCode },
    overrideAttributes: undefined,
    eventTags,
  });
}

export function loginHandler(customData: AuthEventCustomData): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };
  const overrideUserId = merchantCode ? { merchantCode } : undefined;

  optimizelyTrack({
    eventKey: 'login',
    overrideUserId,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function purchaseHandler(customData: PurchaseEventCustomData): void {
  const { merchantCode, order, paymentMethod, userId } = customData;
  const eventTags = {
    products: order.products.reduce(
      (a, { id, trackingId }) => `${a}${trackingId || id}|`,
      '|',
    ),
    productsQuantity: order.products.reduce(
      (a, { quantity }) => a + quantity,
      0,
    ),
    paymentType: paymentMethod,
    merchantCode,
    revenue: Math.floor(order.total * 100),
    order_id: order.id,
  };

  optimizelyTrack({
    eventKey: 'purchase',
    overrideUserId: userId,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function goToCheckoutHandler(customData: GoToCheckoutCustomData): void {
  const { channel, orderId, merchantCode } = customData;
  const eventTags = {
    order_id: orderId,
    merchantCode,
  };

  optimizelyTrack({
    eventKey: 'go_to_checkout',
    overrideUserId: { channel },
    overrideAttributes: undefined,
    eventTags,
  });
}

export function goToCartHandler(): void {
  optimizelyTrack({
    eventKey: 'goToCart',
  });
}

export function addProductHandler(customData: ManageProductCustomData): void {
  const { country, merchantCode, product } = customData;
  const overrideUserId = merchantCode ? { merchantCode } : undefined;
  const eventTags = {
    country,
    merchantCode,
    productName: product.trackingId || product.id,
  };

  optimizelyTrack({
    eventKey: 'addToCart',
    overrideUserId,
    eventTags,
  });
}

export function proceedAsRegisteredUser(
  customData: ProceedAsRegisteredUserCustomData,
): void {
  const { merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  optimizelyTrack({
    eventKey: 'proceed_as_registered_user',
    overrideUserId: undefined,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function goToActivationHandler(
  customData: GoToActivationCustomData,
): void {
  const { merchantCode, orderId, country } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
    country,
  };

  optimizelyTrack({
    eventKey: 'go_to_activation',
    overrideUserId: undefined,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function addressSuggestionSelectedHandler(
  customData: AddressSuggestionSelectedCustomData,
): void {
  const { orderId, merchantCode } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  optimizelyTrack({
    eventKey: 'address_suggestion_selected',
    overrideUserId: undefined,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function skipShopHandler(
  customData: SkipShopEventCustomData = {},
): void {
  const { userId, label, merchantCode, orderId } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
  };

  optimizelyTrack({
    eventKey: 'skip_shop',
    overrideUserId: userId ?? undefined,
    overrideAttributes: undefined,
    eventTags: {
      ...eventTags,
      ...(label && { product: label }),
    },
  });
}

export function moreInfoHandler(customData: MoreInfoCustomData): void {
  const { productName, userId, orderId, merchantCode } = customData;
  const eventTags = {
    merchantCode,
    order_id: orderId,
    productName,
  };

  optimizelyTrack({
    eventKey: 'more_info_click',
    overrideUserId: userId,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function goToPaymentHandler(customData: GoToPaymentCustomData): void {
  const { merchantCode, order } = customData;
  const eventTags = {
    merchantCode,
    order_id: order.id,
  };

  optimizelyTrack({
    eventKey: 'go_to_payment',
    overrideUserId: undefined,
    overrideAttributes: undefined,
    eventTags,
  });
}

export function productDetailsPageViewHandler(): void {
  optimizelyTrack({
    eventKey: 'visited_product_details_page',
    eventTags: { source: 'storefront' },
  });
}
