import type { authenticate } from '@commercelayer/js-auth';

type TokenData = {
  accessToken: string;
  expiresAt: number;
  market: string;
  clientId: string;
};

export class Token {
  private data: TokenData;

  constructor(data: TokenData) {
    this.data = data;
  }

  toJson(): string {
    return JSON.stringify(this.data);
  }

  get accessToken(): string {
    return this.data.accessToken;
  }

  get market(): string {
    return this.data.market;
  }

  get clientId(): string {
    return this.data.clientId;
  }

  get expiresAt(): number {
    return this.data.expiresAt;
  }

  isExpired(): boolean {
    return this.data.expiresAt * 1000 < Date.now();
  }

  static fromData(data: TokenData): Token {
    return new Token(data);
  }

  static fromJson(data: string): Token {
    const parsed = JSON.parse(data) as TokenData;

    return new Token(parsed);
  }

  static fromAuthResponse(
    auth: Awaited<ReturnType<typeof authenticate>>,
    market: string,
    clientId: string,
  ): Token {
    return new Token({
      accessToken: auth.accessToken,
      expiresAt: Math.round(Date.now() / 1000) + auth.expiresIn,
      market,
      clientId,
    });
  }
}
