import {
  AuthProvider as NanoAuthProvider,
  createAxiosClient,
  type OIDCClientOptions,
  type OIDCConfig,
} from '@sumup/react-nanoauth';
import type { FC, PropsWithChildren } from 'react';

import { STORE_STORAGE_PREFIX } from 'shared/infra/account/services/token';
import logger from 'shared/services/logger';
import isServer from 'shared/utils/is-server';

import { getAuthCallbackUrl, getAuthLogoutUrl } from './AuthService';

const OIDC_STORAGE_KEY_PREFIX = 'oidc:';

export const prefixMigrationStorage: Storage = isServer
  ? undefined
  : {
      getItem: (key) => {
        const curVal = window.localStorage.getItem(key);

        if (!key.startsWith(STORE_STORAGE_PREFIX)) {
          return curVal;
        }

        const [, prevKey] = key.split(STORE_STORAGE_PREFIX);
        const prevVal = window.localStorage.getItem(prevKey);

        if (prevVal !== null) {
          window.localStorage.removeItem(prevKey);

          if (curVal === null) {
            window.localStorage.setItem(key, prevVal);
          }
        }

        return curVal || prevVal;
      },
      setItem: (key, value) => window.localStorage.setItem(key, value),
      removeItem: (key) => window.localStorage.removeItem(key),
      clear: () => window.localStorage.clear(),
      key: (index) => window.localStorage.key(index),
      length: window.localStorage.length,
    };

const oidcConfig: OIDCConfig = {
  issuer: process.env.NEXT_PUBLIC_OIDC_ISSUER,
  clientId: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
  redirectURI: getAuthCallbackUrl(),
  postLogoutRedirectURI: getAuthLogoutUrl(),
  scopes: ['openid', 'offline', 'classic', 'email'],
};

const oidcClientOptions: Partial<OIDCClientOptions> = {
  createHTTPClient: createAxiosClient,
  ...(!isServer && { storage: prefixMigrationStorage }),
  localStorageKeyPrefix: `${STORE_STORAGE_PREFIX}${OIDC_STORAGE_KEY_PREFIX}`,
};

/**
 * A wrapper around the react-nanoauth AuthProvider enabling
 * feature toggling.
 */
export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const handleError = (err): void => {
    logger.error(err, '[AUTH] authentication error');
  };

  return (
    <NanoAuthProvider
      onError={handleError}
      oidcConfig={oidcConfig}
      oidcClientOptions={oidcClientOptions}
    >
      {children}
    </NanoAuthProvider>
  );
};
