import { useEffect } from 'react';

import logger from 'shared/services/logger';
import { optimizely } from 'shared/services/optimizely';

const THRESHOLD_TIMES = [0.5, 1, 5];

/*
 * The events sent from this method will be used to analyse and research
 * how many users are bots and polluting our metrics/data
 */
export function useOptimizelyUserTimers(): void {
  useEffect(() => {
    optimizely
      .onReady()
      .then(() => {
        THRESHOLD_TIMES.forEach((time) => {
          const timeString = time < 60 ? `${time}_sec` : `${time / 60}_min`;
          const eventName = `shop_userTimePerSession_${timeString}`;

          setTimeout(() => {
            optimizely.track(eventName);
          }, time * 1000);
        });
      })
      .catch(logger.error);
  }, []);
}
