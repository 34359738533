import logger from 'shared/services/logger';

type ListenerCallback = (data?: unknown) => void;

interface Listeners {
  [eventName: string]: ListenerCallback[];
}

interface ITracker {
  dispatch: (eventName: string, customData?: unknown) => Promise<void>;
  listen: (eventName: string, handler: ListenerCallback) => void;
}

export function Tracker(): ITracker {
  const listeners: Listeners = {};

  const dispatch = async (
    eventName: string,
    customData?: unknown,
  ): Promise<void> => {
    /**
     * Send tracker dispatches to the end of the callstack.
     * This ensures that tracking events trigger only after other
     * modules instances are properly initialised.
     */
    await Promise.resolve();

    if (!listeners[eventName]) {
      logger.warn(`There's no listener registered for the event ${eventName}.`);
      return;
    }

    logger.debug(
      `[TRACKING]: ${JSON.stringify(
        {
          eventName,
          customData,
        },
        null,
        2,
      )}`,
    );

    try {
      listeners[eventName].forEach((listener) => {
        listener(customData);
      });
    } catch (err: unknown) {
      const error = err instanceof Error ? err : new Error(String(err));
      logger.error(
        error,
        `[TRACKING] Error while executing listener for the event "${eventName}": `,
      );
    }
  };

  const listen = (eventName: string, callback: ListenerCallback): void => {
    if (!listeners[eventName]) {
      listeners[eventName] = [];
    }

    listeners[eventName].push(callback);
  };

  return {
    dispatch,
    listen,
  };
}

export const tracker = Tracker();
