import { getSumupCookieDomain } from 'utils/cookies';

import type { Experiment } from './types';

export const SUMUP_COOKIE_DOMAIN = getSumupCookieDomain();

export const PLATFORM = 'storefront';
export const IN_APP_PARAM = 'in_app';

export const MISSING_VALUE = 'unknown';

export const EXPERIMENT_USER_COOKIE_NAME = 'optimizely_experiment_user';

export const NEW_SHOP_ROLLOUT_VARIATION = 'new_shop';
export const NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS = [
  '_shop__v2______eu_first_bucket_old_shop_vs__new_shop',
  '_shop__v2_eu______it_old_shop_vs_new_shop',
  '_shop__v2______eu_de_nl_be_ch_old_shop_vs_new_shop',
  '_shop___canary__v2______eu_second_bucket_old_shop_vs__new_shop',
  '_shop__v2_br______old_shop_or_new_shop',
];

export const BA_BUNDLE_FLAG = '_shop_website_business_account_bundle';
export const TRANSPARENT_FEE_FLAG = '_shop__transparent_fees';

export const WEBSITE_PDP_CONFIGURATOR = '_website__product_configurator';
export const WEBSITE_PDP_CONFIGURATOR_CONTROL = 'off';
export const WEBSITE_PDP_CONFIGURATOR_VARIATION = 'on';
export const WEBSITE_PDP_CONFIGURATOR_EXP: Experiment = {
  id: 'website_pdp',
  type: 'component',
  activeRoutes: [
    '/website/product-selection/[productName]',
    '/website/product-selection',
    '/website/cart/[id]',
    '/website/thank-you',
  ],
  variations: [
    {
      id: WEBSITE_PDP_CONFIGURATOR_CONTROL,
    },
    {
      id: WEBSITE_PDP_CONFIGURATOR_VARIATION,
    },
  ],
  websiteSync: true,
  metadata: {
    owner: 'explore', // current name - "website-journey-squad"
    experiment:
      'https://app.optimizely.com/v2/projects/18423480104/flags/manage/_website__product_configurator',
  },
};

export const RUNNING_FEATURE_FLAGS = [
  BA_BUNDLE_FLAG,
  TRANSPARENT_FEE_FLAG,
  WEBSITE_PDP_CONFIGURATOR,
];

export const RUNNING_EXPERIMENTS: Experiment[] = [WEBSITE_PDP_CONFIGURATOR_EXP];
