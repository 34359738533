import { tracker } from 'shared/services/tracker/tracker';
import {
  EVENTS,
  type AddressSuggestionSelectedCustomData,
  type AuthEventCustomData,
  type BusinessAccountSelectedCustomData,
  type BusinessAccountUnselectedCustomData,
  type GoToActivationCustomData,
  type GoToCartCustomData,
  type GoToCheckoutCustomData,
  type GoToPaymentCustomData,
  type GoToWebsiteCustomData,
  type ManageProductCustomData,
  type MoreInfoCustomData,
  type ProceedAsRegisteredUserCustomData,
  type PurchaseAttemptCustomData,
  type PurchaseEventCustomData,
  type PurchaseFailureEventCustomData,
  type SkipShopEventCustomData,
} from 'shared/services/tracker/types';

const {
  PAGEVIEW,
  ADD_PRODUCT,
  ADD_PRODUCT_FAILURE,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_PRODUCT_QUANTITY_FAILURE,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_FAILURE,
  GO_TO_CART,
  GO_TO_CHECKOUT,
  PROCEED_AS_REGISTERED_USER,
  SIGNUP,
  SIGNUP_FAILURE,
  LOGIN,
  LOGIN_FAILURE,
  PURCHASE_ATTEMPT,
  PURCHASE,
  PURCHASE_GA4,
  PURCHASE_FAILURE,
  GO_TO_ACTIVATION,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CHECKOUT,
  CREATE_CHECKOUT_FAILURE,
  ORDER_CREATED,
  ORDER_CREATED_FAILURE,
  ORDER_PLACED,
  ORDER_PLACEMENT_FAILURE,
  PAYMENT_CALLBACK_INVOKED,
  OPTIMIZELY_INIT,
  OPTIMIZELY_INIT_FAILURE,
  EVENT_404,
  EVENT_500,
  MORE_INFO,
  SKIP_SHOP,
  SEARCH_ADDRESS_FAILURE,
  ADDRESS_SUGGESTION_SELECTED,
  ORDER_STATUS_FETCHED,
  ORDER_STATUS_FETCH_FAILED,
  ECOM_CLIENT_INIT,
  ECOM_CLIENT_INIT_FAILURE,
  GO_TO_PAYMENT,
  INPUT_ERROR,
  VOUCHER_APPLIED,
  GO_TO_WEBSITE,
  BA_LEARN_MORE_OPEN,
  BA_LEARN_MORE_CLOSE,
  BA_SELECTED,
  BA_UNSELECTED,
  BA_CONDITIONS_MODAL_OPEN,
  BA_CONDITIONS_MODAL_CLOSE,
} = EVENTS;

export const dispatchPageViewEvent = (customData?: unknown): Promise<void> =>
  tracker.dispatch(PAGEVIEW, customData);

export const dispatchAddProductEvent = (
  customData?: ManageProductCustomData,
): Promise<void> => tracker.dispatch(ADD_PRODUCT, customData);

export const dispatchAddProductFailureEvent = (
  customData?: ManageProductCustomData,
): Promise<void> => tracker.dispatch(ADD_PRODUCT_FAILURE, customData);

export const dispatchChangeProductQuantityEvent = (
  customData?: ManageProductCustomData,
): Promise<void> => tracker.dispatch(CHANGE_PRODUCT_QUANTITY, customData);

export const dispatchChangeProductQuantityFailureEvent = (
  customData?: ManageProductCustomData,
): Promise<void> =>
  tracker.dispatch(CHANGE_PRODUCT_QUANTITY_FAILURE, customData);

export const dispatchRemoveProductEvent = (
  customData?: ManageProductCustomData,
): Promise<void> => tracker.dispatch(REMOVE_PRODUCT, customData);

export const dispatchRemoveProductFailureEvent = (
  customData?: ManageProductCustomData,
): Promise<void> => tracker.dispatch(REMOVE_PRODUCT_FAILURE, customData);

export const dispatchGoToCartEvent = (
  customData?: GoToCartCustomData,
): Promise<void> => tracker.dispatch(GO_TO_CART, customData);

export const dispatchGoToCheckoutEvent = (
  customData?: GoToCheckoutCustomData,
): Promise<void> => tracker.dispatch(GO_TO_CHECKOUT, customData);

export const dispatchProceedAsRegisteredUserEvent = (
  customData?: ProceedAsRegisteredUserCustomData,
): Promise<void> => tracker.dispatch(PROCEED_AS_REGISTERED_USER, customData);

export const dispatchSignupEvent = (
  customData: AuthEventCustomData,
): Promise<void> => tracker.dispatch(SIGNUP, customData);

export const dispatchSignupFailureEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(SIGNUP_FAILURE, customData);

export const dispatchLoginEvent = (
  customData?: AuthEventCustomData,
): Promise<void> => tracker.dispatch(LOGIN, customData);

export const dispatchPurchaseAttemptEvent = (
  customData?: PurchaseAttemptCustomData,
): Promise<void> => tracker.dispatch(PURCHASE_ATTEMPT, customData);

export const dispatchLoginFailureEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(LOGIN_FAILURE, customData);

export const dispatchPurchaseEvent = (
  customData: PurchaseEventCustomData,
): Promise<void> => tracker.dispatch(PURCHASE, customData);

export const dispatchPurchaseEventGA4 = (
  customData: PurchaseEventCustomData,
): Promise<void> => tracker.dispatch(PURCHASE_GA4, customData);

export const dispatchPurchaseFailureEvent = (
  customData: PurchaseFailureEventCustomData,
): Promise<void> => tracker.dispatch(PURCHASE_FAILURE, customData);

export const dispatchGoToActivationEvent = (
  customData?: GoToActivationCustomData,
): Promise<void> => tracker.dispatch(GO_TO_ACTIVATION, customData);

export const dispatchCreateCustomer = (customData?: unknown): Promise<void> =>
  tracker.dispatch(CREATE_CUSTOMER, customData);

export const dispatchCreateCustomerFailure = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(CREATE_CUSTOMER_FAILURE, customData);

export const dispatchCreateCheckout = (customData?: unknown): Promise<void> =>
  tracker.dispatch(CREATE_CHECKOUT, customData);

export const dispatchCreateCheckoutFailure = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(CREATE_CHECKOUT_FAILURE, customData);

export const dispatchOrderCreateEvent = (customData?: unknown): Promise<void> =>
  tracker.dispatch(ORDER_CREATED, customData);
export const dispatchOrderCreateFailureEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(ORDER_CREATED_FAILURE, customData);

export const dispatchOrderPlacedEvent = (customData?: unknown): Promise<void> =>
  tracker.dispatch(ORDER_PLACED, customData);

export const dispatchOrderPlacementFailureEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(ORDER_PLACEMENT_FAILURE, customData);

export const dispatchPaymentCallbackInvokedEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(PAYMENT_CALLBACK_INVOKED, customData);

export const dispatchOptimizelyInitEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(OPTIMIZELY_INIT, customData);

export const dispatchOptimizelyInitFailureEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(OPTIMIZELY_INIT_FAILURE, customData);

export const dispatch404Event = (customData?: unknown): Promise<void> =>
  tracker.dispatch(EVENT_404, customData);

export const dispatch500Event = (customData?: unknown): Promise<void> =>
  tracker.dispatch(EVENT_500, customData);

export const dispatchMoreInfoClick = (
  customData?: MoreInfoCustomData,
): Promise<void> => tracker.dispatch(MORE_INFO, customData);

export const dispatchSkipShopEvent = (
  customData?: SkipShopEventCustomData,
): Promise<void> => tracker.dispatch(SKIP_SHOP, customData);

export const dispatchSearchAddressError = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(SEARCH_ADDRESS_FAILURE, customData);

export const dispatchAddressSuggestionSelectedEvent = (
  customData?: AddressSuggestionSelectedCustomData,
): Promise<void> => tracker.dispatch(ADDRESS_SUGGESTION_SELECTED, customData);

export const dispatchOrderStatusEvent = (customData?: unknown): Promise<void> =>
  tracker.dispatch(ORDER_STATUS_FETCHED, customData);

export const dispatchOrderStatusFailedEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(ORDER_STATUS_FETCH_FAILED, customData);

export const dispatchEcomClientInit = (customData?: unknown): Promise<void> =>
  tracker.dispatch(ECOM_CLIENT_INIT, customData);

export const dispatchEcomClientInitFailure = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(ECOM_CLIENT_INIT_FAILURE, customData);

export const dispatchGoToPaymentEvent = (
  customData?: GoToPaymentCustomData,
): Promise<void> => tracker.dispatch(GO_TO_PAYMENT, customData);

export const dispatchInputErrorEvent = (customData?: unknown): Promise<void> =>
  tracker.dispatch(INPUT_ERROR, customData);

export const dispatchVoucherAppliedEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(VOUCHER_APPLIED, customData);

export const dispatchGoToWebsiteEvent = (
  customData?: GoToWebsiteCustomData,
): Promise<void> => tracker.dispatch(GO_TO_WEBSITE, customData);

// BA_LEARN_MORE_OPEN,
export const dispatchBusinessAccountLearnMoreOpenEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(BA_LEARN_MORE_OPEN, customData);
// BA_LEARN_MORE_CLOSE,
export const dispatchBusinessAccountLearnMoreCloseEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(BA_LEARN_MORE_CLOSE, customData);
// BA_SELECTED,
export const dispatchBusinessAccountSelectedEvent = (
  customData: BusinessAccountSelectedCustomData,
): Promise<void> => tracker.dispatch(BA_SELECTED, customData);
// BA_UNSELECTED,
export const dispatchBusinessAccountUnselectedEvent = (
  customData: BusinessAccountUnselectedCustomData,
): Promise<void> => tracker.dispatch(BA_UNSELECTED, customData);
// BA_CONDITIONS_MODAL_OPEN,
export const dispatchBusinessAccountConditionsModalOpenEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(BA_CONDITIONS_MODAL_OPEN, customData);
// BA_CONDITIONS_MODAL_CLOSE
export const dispatchBusinessAccountConditionsModalCloseEvent = (
  customData?: unknown,
): Promise<void> => tracker.dispatch(BA_CONDITIONS_MODAL_CLOSE, customData);

export const dispatchProductDetailsPageViewEvent = (): Promise<void> =>
  tracker.dispatch(EVENTS.VISITED_PRODUCT_DETAILS_PAGE);
