import type { UserAttributes } from '@optimizely/optimizely-sdk';
import {
  OptimizelyProvider as OptimizelyFSProvider,
  createInstance,
  enums,
  setLogLevel,
} from '@optimizely/react-sdk';
import { useRouter } from 'next/router';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import isServer from 'shared/utils/is-server';

import logger from '../logger';

import {
  NEW_SHOP_ROLLOUT_VARIATION,
  NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS,
} from './constants';
import { useOptimizelyUserTimers } from './hooks';

import {
  createSharedIframeStorage,
  forceOptimizelyVariation,
  getAttributes,
  getUserId,
  handleActivate,
  isOptimizelyReady,
  optimizely,
} from './index';

type User = {
  id: string;
  attributes?: UserAttributes;
};

optimizely.notificationCenter.addNotificationListener(
  enums.NOTIFICATION_TYPES.ACTIVATE,
  handleActivate,
);

setLogLevel(logger.getLevel());

const getOptimizelyUser = async (countryCode: string): Promise<User> => {
  await createSharedIframeStorage();
  const optimizelyUser = {
    id: getUserId(),
    attributes: getAttributes(countryCode),
  };

  return optimizelyUser;
};

/** Programmatically force an experiment activation on the shop
 * this is used only temporarily to activate the experiments attributed
 * on Cloudfront and enable experiment data to be sent to GA.
 */
const forceUserRolloutVariation = (user: User, variation: string): void => {
  NEW_SHOP_VS_OLD_SHOP_EXPERIMENTS.forEach((experiment) => {
    forceOptimizelyVariation(
      experiment,
      optimizely,
      user.id,
      user.attributes,
      variation,
    );
  });
};

export const OptimizelyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isReady, setReady] = useState(false);
  const { locale, isPreview } = useRouter();
  const countryCode = getCountryCodeFromISOLocale(locale);

  useOptimizelyUserTimers();

  useEffect(() => {
    getOptimizelyUser(countryCode).then(setUser).catch(logger.error);
  }, [countryCode]);

  useEffect(() => {
    isOptimizelyReady()
      .then(({ success }) => setReady(success))
      .catch(logger.error);
  }, []);

  useEffect(() => {
    if (user?.id && isReady) {
      forceUserRolloutVariation(user, NEW_SHOP_ROLLOUT_VARIATION);
    }
  }, [user, isReady]);

  return (
    <OptimizelyFSProvider
      optimizely={isPreview ? createInstance({}) : optimizely}
      user={!isServer && getOptimizelyUser(countryCode)}
    >
      {children}
    </OptimizelyFSProvider>
  );
};
