import type { Reducer } from 'react';

import {
  PaymentActionTypes,
  type PaymentActions,
  type PaymentState,
} from './types';

type StoreManagementState = {
  loading: boolean;
  error: string;
};

export type PaymentGlobalState = PaymentState & StoreManagementState;

export const INITIAL_STATE: Readonly<PaymentGlobalState> = {
  id: null,
  reference: null, // payment_method name
  checkoutId: null,
  paymentCode: null,
  documentDownloadUrl: null,
  loading: false,
  error: null,
};

const reducer: Reducer<PaymentGlobalState, PaymentActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case PaymentActionTypes.RESET_PAYMENT: {
      return INITIAL_STATE;
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case PaymentActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.isLoading ?? true,
        error: null,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE:
    case PaymentActionTypes.SET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        id: action.payload.id,
        reference: action.payload.reference,
        loading: false,
        error: null,
      };
    }
    case PaymentActionTypes.ATTACH_CHECKOUT_ID: {
      return {
        ...state,
        checkoutId: action.payload.checkoutId,
      };
    }
    case PaymentActionTypes.ATTACH_PAYMENT_CODE: {
      return {
        ...state,
        paymentCode: action.payload.code,
      };
    }
    case PaymentActionTypes.ATTACH_DOWNLOAD_URL: {
      return {
        ...state,
        documentDownloadUrl: action.payload.documentDownloadUrl,
      };
    }
    default:
      return state;
  }
};

export default reducer;
